<template>
  <div style="border: 1px solid #ccc">
    <Toolbar
      style="border-bottom: 1px solid #ccc"
      :editor="editor"
      :defaultConfig="toolbarConfig"
      :mode="mode"
    />
    <Editor
      :style="`height: ${height}px;overflow-y: hidden`"
      v-model="html"
      :defaultConfig="editorConfig"
      :mode="mode"
      @onCreated="onCreated"
    />
  </div>
</template>

<script>
import { Editor, Toolbar } from "@wangeditor/editor-for-vue";
import "@wangeditor/editor/dist/css/style.css";
export default {
  name: "WangEdit",
  components: { Editor, Toolbar },
  props: {
    value: {
      type: String,
      default: "<p></p>",
      required: false,
    },
    height: {
      type: Number,
      default: 300,
      required: false,
    },
    mode: {
      type: String,
      default: "default",
      required: false,
    },
    toolbarConfig: {
      type: Object,
      required: false,
      default: () => {
        return {
          excludeKeys: [
            //排除表情、全屏
            "emotion",
            "fullScreen",

            // "group-image",
            // "group-video",
            // "codeBlock",
            // "code",
          ],
        };
      },
    },
    editorConfig: {
      type: Object,
      required: false,
      default: () => {
        return {
          placeholder: "请输入内容...",
          MENU_CONF: {
            //上传图片
            uploadImage: {
              server: process.env.VUE_APP_BASE_FILE_API,
              fieldName: "file",
              customInsert(res, insertFn) {
                // JS 语法
                // res 即服务端的返回结果

                // 从 res 中找到 url alt href ，然后插入图片
                insertFn(res.data.url, "no pic", res.data.filename);

                // setContentPic(res.data.filename)
              },
            },
            //上传视频
            uploadVideo: {
              server: process.env.VUE_APP_BASE_FILE_API,
              fieldName: "file",
              customInsert(res, insertFn) {
                // JS 语法
                // res 即服务端的返回结果

                // 从 res 中找到 url alt href ，然后插入
                insertFn(res.data.url, res.data.filename);

                // setContentPic(res.data.filename)
              },
            },
          },
        };
      },
    },
  },

  data() {
    return {
      editor: null,
      html: "<p></p>",
    };
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor); // 一定要用 Object.seal() ，否则会报错
    },
  },
  watch: {
    value: {
      handler(newVal, oldVal) {
        this.html = newVal;
      },
      deep: true,
      immediate: true
    },
    html: {
      handler(newVal, oldVal) {
        this.$emit("input", newVal);
      },
      deep: true,
      immediate: true
    }
  },
  beforeDestroy() {
    const editor = this.editor;
    if (editor == null) return;
    editor.destroy(); // 组件销毁时，及时销毁编辑器
  },
};
</script>
