import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/Layout.vue'
import { getToken } from '@/utils/auth'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		redirect: "/login"
	},
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue')
  },
  {
    path: '/admin',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: '',
        name: 'Welcome',
        component: () => import('@/views/Welcome.vue')
      },
      {
        path: 'admin',
        name: 'admin',
        component: () => import('@/views/Admin.vue')
      },
      {
        path: 'designResource',
        name: 'designResource',
        component: () => import('@/views/DesignResource.vue')
      },
      {
        path: 'silverStyle',
        name: 'silverStyle',
        component: () => import('@/views/SilverStyle.vue')
      },
      {
        path: 'users',
        name: 'users',
        component: () => import('@/views/Users.vue')
      },
    ]
  },
  {
    path: '/401',
    name: '401',
    component: () => import('@/views/401.vue')
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/404.vue')
  },
]

const router = new VueRouter({
  routes
})

//无需登录的页面
const whiteList = [
  "/login",
  "/401",
  "/404"
]

//路由拦截
router.beforeEach((to, from, next) => {
	NProgress.start();
  //console.log(to);
  //console.log(from);
  /* 路由发生变化修改页面title */
  // if (to.meta.title) {
  //   document.title = to.meta.title
  // };
  const isLogin = getToken()
  if (isLogin) { //登录之后直接放行
    next()
  } else {
    //无需登录的页面放行
    if (whiteList.some(s => to.fullPath.includes(s)) || whiteList.some(s => to.fullPath.startsWith(s))) {
      next();
    } else {
      next({
        path: '/login'
      })
    };
  };
})

//跳转之后
router.afterEach(transition => {
  NProgress.done();
});

export default router