<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'VueTemplateApp',

  data() {
    return {
      
    };
  },

  mounted() {
    //设置网页Title
    document.getElementsByTagName("title")[0].innerText = this.$sysTitle
  },

  methods: {
    
  },
};
</script>

<style lang="scss">
//前台版心
.area{
  width: 1260px;
  margin: 0 auto;
  overflow: hidden;
}
//过渡
.fade-enter{
  visibility: hidden;
  opacity: 0;
}
.fade-leave-to{
  display: none;
}
.fade-enter-active,
.fade-leave-active{
  transition: opacity .2s ease;
}
.fade-enter-to,
.fade-leave{
  visibility: visible;
  opacity: 1;
}
</style>