<template>
  <div :class="'section-title-container ' + theme">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'SectionTitle',

  props: {
    theme: {
        type: String,
        default: 'theme1',
        required: false
    }
  }
};
</script>
<style lang="scss" scoped>
.section-title-container{
    min-height: 30px;
    font-size: 24px;
    font-weight: 700;
}
.theme1{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 24px;
    padding-left: 20px;
    color: $--color-primary;
    border-left: 4px solid $--color-primary;
}

.theme2{
    display: flex;
    align-items: center;
    justify-content: center;
    color: $--color-primary;
    border: 1px dashed $--color-primary;
    border-radius: 6px;
    padding: 8px 0;
}

.theme3{
    display: flex;
    align-items: center;
    justify-content: center;
    background: $--color-primary;
    border-radius: 8px;
    font-weight: normal;
    color: #fff;
    padding: 8px 0;
}

.theme4{
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 2px solid $--color-primary;
    color: $--color-primary;
    padding: 8px 0;
}

.theme5{
    display: flex;
    align-items: center;
    justify-content: center;
    color: $--color-primary;
    border: 1px solid $--color-primary;
    border-radius: 6px;
    padding: 8px 0;
}

</style>