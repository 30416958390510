import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'normalize.css/normalize.css' //样式重置 https://www.jianshu.com/p/f50bf86597cb
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/styles/common.css'
import '@/assets/styles/element-variables.scss' //主题变量
import UploadImg from '@/components/UploadImg.vue'
import WangEdit from '@/components/WangEdit.vue'
import SectionTitle from '@/components/SectionTitle.vue'
Vue.config.productionTip = false
Vue.use(ElementUI)

Vue.prototype.$baseFileUrl = process.env.VUE_APP_BASE_FILE_URL
Vue.prototype.$baseFileApi = process.env.VUE_APP_BASE_FILE_API
Vue.prototype.$sysTitle = process.env.VUE_APP_SYSTITLE

// 全局组件挂载
Vue.component('UploadImg', UploadImg)
Vue.component('WangEdit', WangEdit)
Vue.component('SectionTitle', SectionTitle)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

