<template>
  <el-upload
    class="avatar-uploader"
    :action="$baseFileApi"
    :show-file-list="false"
    :on-success="handleAvatarSuccess"
    :before-upload="beforeAvatarUpload"
  >
    <img v-if="imageUrl" :src="imageUrl" class="avatar" alt="图片不见了" />
    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
  </el-upload>
</template>

<script>
export default {
  name: "UploadImg",

  props: {
    value: {
        type: String,
        default: "",
        required: true,
    }
  },

  watch: {
    value: {
      handler(newVal, oldVal) {
       this.filename =  newVal
       if(newVal){
        this.imageUrl = this.$baseFileUrl + newVal
       }else{
        this.imageUrl = ''
       }
       
      },
      deep: true,
      immediate: true
    }
  },

  data() {
    return {
      filename: "",
      imageUrl: ""
    };
  },
  methods: {
    handleAvatarSuccess(res, file) {
      this.imageUrl = res.data.url;
      this.filename = res.data.filename;
      this.$emit('input',res.data.filename)
    },
    beforeAvatarUpload(file) {
      //   const isJPG = file.type === "image/jpeg";
      //   const isLt2M = file.size / 1024 / 1024 < 2;

      //   if (!isJPG) {
      //     this.$message.error("上传头像图片只能是 JPG 格式!");
      //   }
      //   if (!isLt2M) {
      //     this.$message.error("上传头像图片大小不能超过 2MB!");
      //   }
      //   return isJPG && isLt2M;
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.avatar-uploader {
  ::v-deep .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &:hover {
      border-color: #409eff;
    }
    .avatar-uploader-icon {
      font-size: 28px;
      color: #8c939d;
      width: 100px;
      height: 100px;
      line-height: 100px;
      text-align: center;
    }
    .avatar {
      width: 100px;
      height: 100px;
      display: block;
    }
  }
}
</style>
