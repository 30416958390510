import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
import createPersistedState from "vuex-persistedstate";
import { getInfoByTokenAPI } from '@/api/system'
import { removeToken,removeVuexCache} from '@/utils/auth'
export default new Vuex.Store({
  state: {
    user: {
      name: '',
      head: ''
    },
    token: '',
    role: ''
  },
  getters: {
    name: state => state.user.name,
    head: state => state.user.head,
    token: state => state.token,
    role: state => state.role,
  },
  mutations: {
    SET_USER(state, data) {
      state.user = data
    },
    SET_TOKEN(state, data) {
      state.token = data
    },
    SET_ROLE(state,data){
      state.role = data
    }
  },
  actions: {
    //获取用户信息
    GET_USER_INFO({ commit }){
      getInfoByTokenAPI().then(res => {
        commit('SET_USER', res.data.user)
        commit('SET_ROLE', res.data.role)
      })
    },
    //退出
    LOGOUT({ commit }){
      return new Promise((resolve, reject) => {
        commit('SET_ROLE', '')
        commit('SET_TOKEN', '') //清除token
        commit('SET_USER', { name: '', head: '' }) //清除用户信息
        removeToken() //清除token
        removeVuexCache() //清除vuex缓存
        resolve()
      })
    }
  },
  plugins: [createPersistedState()] //持久化vuex
})
